import styles from './css/Sidebar.module.css';
import { useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { useQuery } from 'react-query';
import MenuItems from './MenuItems';
import {
  getGlobalItems,
  getObjects,
  getSettings,
} from '../../utils/sidebarTabs';
import Logo from '../../assets/Main Logo.png';
import IconButton from '@mui/material/IconButton';
import KeyboardTabOutlinedIcon from '@mui/icons-material/KeyboardTabOutlined';
import Avatar from '@mui/material/Avatar';
import { getRecord } from '../../api/crud';
import { BeatLoader } from 'react-spinners';

const CustomLoader = ({ loading }) => {
  return (
    <BeatLoader
      color="var(--primary-color)"
      loading={loading}
      size={10}
      aria-label="Loading Spinner"
      data-testid="loader"
    />
  );
};

export default function Sidebar({ isMenuOpen, toggleDrawer }) {
  const { getUserId } = useContext(AuthContext);
  const userId = getUserId();
  const { data: user, isLoading } = useQuery({
    queryFn: () => getRecord('user', userId),
    queryKey: ['logged-in-user-sidebar'],
  });
  const collapsedSidebarStyles = {
    width: '50px',
  };

  const iconRotationStyle = {
    transform: isMenuOpen ? 'rotate(180deg)' : '',
    transition: 'transform 0.3s ease',
  };

  return (
    <section
      className={styles['sidebar']}
      style={!isMenuOpen ? collapsedSidebarStyles : {}}
    >
      <div className={styles['logo-container']}>
        {isMenuOpen && <img alt="company logo" src={Logo} />}
        <IconButton aria-label="toggle-drawer" onClick={toggleDrawer}>
          <KeyboardTabOutlinedIcon style={iconRotationStyle} />
        </IconButton>
      </div>
      <div className={styles['menu-items-container']}>
        <div className={styles['logged-in-user']}>
          <Avatar
            variant="rounded"
            alt={`${user?.firstName} ${user?.lastName}`}
            src={user?.profilePicture}
          />
          {isMenuOpen && (
            isLoading ? (
              <CustomLoader loading={isLoading} />
              ) : (
              <div>
                <h4>{`${user?.firstName?.charAt(0)}. ${user?.lastName}`}</h4>
                <small>{user?.role?.name}</small>
              </div>
            )
          )}
        </div>
        {isMenuOpen && (
          <small className={styles['sidebar-header']}>Global</small>
        )}
        <MenuItems items={getGlobalItems} isMenuOpen={isMenuOpen} />
        <hr />
        {isMenuOpen && <small className={styles['sidebar-header']}>Main</small>}
        <MenuItems items={getObjects} isMenuOpen={isMenuOpen} />
        <hr />
        {isMenuOpen && (
          <small className={styles['sidebar-header']}>Settings</small>
        )}
        <MenuItems items={getSettings} isMenuOpen={isMenuOpen} />
      </div>
    </section>
  );
}
