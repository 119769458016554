import {  BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import Login from '../views/auth/login/Login';
import ProtectedRoute from './ProtectedRoute';
import PageLayout from '../layouts/PageLayout';
// import EmailsPage from '../views/emails/EmailsPage';
import Home from '../views/home/Home';
import LeadsDashboard from '../views/dashboards/leadsDashboard/LeadsDashboard';
import DealsDashboard from '../views/dashboards/dealsDashboard/DealsDashboard';
import OrdersDashboard from '../views/dashboards/ordersDashboard/OrdersDashboard';
import RevenueTargetsDashboard from '../views/dashboards/targets/RevenueTargetsDashboard';
import SalesTargetsDashboard from '../views/dashboards/targets/SalesTargetsDashboard';
import Activities from '../views/listviews/activities/Activities';
import Tasks from '../views/listviews/tasks/Tasks';
import Cases from '../views/listviews/cases/Cases';
// import TenderCategories from '../views/tenders/TenderCategories';
// import Tenders from '../views/tenders/Tenders';
import Leads from '../views/listviews/leads/Leads';
import Deals from '../views/listviews/deals/Deals';
import Orders from '../views/listviews/orders/Orders';
import Contacts from '../views/listviews/contacts/Contacts';
import Accounts from '../views/listviews/accounts/Accounts';
import Users from '../views/listviews/users/Users';
import Tenants from '../views/listviews/tenants/Tenants';
import TenantPlans from '../views/listviews/tenantPlans/TenantPlans';
// import StandardCharts from '../views/standardCharts/StandardCharts';
import Parameters from '../views/parameters/Parameters';
import RolesAndPermissions from '../views/rolesAndPermissions/RolesAndPermissions';
import Profile from '../views/profile/Profile';
import ResetPassword from '../views/auth/resetPassword/ResetPassword';
import ForgotPassword from '../views/auth/resetPassword/ForgotPassword';
import PageNotFound from '../views/auth/pageNotFound/PageNotFound';

export default function PageRouter(){
    const [cookies,] = useCookies(['tenantId']);
    const tenantId = cookies.tenantId; // Get the tenantId of the logged in user
    const digitalFingerprintTenantId = process.env.REACT_APP_DIGITAL_FINGERPRINT_TENANT_ID; // Get the tenantId of Digital Fingerprint

    const paths = [
        // // { component: <Login />, link: '/login', isAuthRequired: false},
        // { component: <ResetPassword />, link: '/password-reset/:userId/:token', isAuthRequired: false},
        // { component: <ForgotPassword />, link: '/forgot-password', isAuthRequired: false},
        // { component: <PageLayout type='emails' variant='emails_page' component={<EmailsPage />} />, link: '/emails', isAuthRequired: true},
        // { component: <PageLayout type='emails' variant='emails_page' component={<EmailsPage />} />, link: '/emails/:mailId', isAuthRequired: true},
        // { component: <PageLayout type='emails' variant='emails_page' component={<EmailsPage />} />, link: '/emails/unseen', isAuthRequired: true},
        // { component: <PageLayout type='emails' variant='emails_page' component={<EmailsPage />} />, link: '/emails/unseen/:mailId', isAuthRequired: true},
        { component: <PageLayout type='home' variant='dashboard_layout' component={<Home />} />, link: '/', isAuthRequired: true},
        { component: <PageLayout type='dashboard' variant='dashboard_layout' component={<LeadsDashboard />} />, link: '/leads-dashboard', isAuthRequired: true},
        { component: <PageLayout type='dashboard' variant='dashboard_layout' component={<DealsDashboard />} />, link: '/deals-dashboard', isAuthRequired: true},
        { component: <PageLayout type='dashboard' variant='dashboard_layout' component={<OrdersDashboard />} />, link: '/orders-dashboard', isAuthRequired: true},
        { component: <PageLayout type='dashboard' variant='dashboard_layout' component={<SalesTargetsDashboard />} />, link: '/sales-targets', isAuthRequired: true},
        { component: <PageLayout type='dashboard' variant='dashboard_layout' component={<RevenueTargetsDashboard />} />, link: '/revenue-targets', isAuthRequired: true},
        // { component: <PageLayout variant='dashboard_layout' component={<TenderCategories />} />, link: '/tender-categories', isAuthRequired: true},
        // { component: <PageLayout variant='dashboard_layout' component={<Tenders />} />, link: '/tenders/:url', isAuthRequired: true},
        { component: <PageLayout type='activities' component={<Activities />} />, link: '/activities', isAuthRequired: true},
        { component: <PageLayout type='tasks' component={<Tasks />} />, link: '/tasks', isAuthRequired: true},
        { component: <PageLayout type='tasks' component={<Tasks />} />, link: '/tasks/kanban', isAuthRequired: true},
        { component: <PageLayout type='tasks' component={<Tasks  />} />, link: '/task/list/:listId', isAuthRequired: true},
        { component: <PageLayout type='tasks' component={<Tasks showDetailsPage={true} />} />, link: '/task/:recordId', isAuthRequired: true},
        { component: <PageLayout type='cases' component={<Cases />} />, link: '/cases', isAuthRequired: true},
        { component: <PageLayout type='cases' component={<Cases />} />, link: '/cases/kanban', isAuthRequired: true},
        { component: <PageLayout type='cases' component={<Cases  />} />, link: '/case/list/:listId', isAuthRequired: true},
        { component: <PageLayout type='cases' component={<Cases showDetailsPage={true} />} />, link: '/case/:recordId', isAuthRequired: true},
        { component: <PageLayout type='leads' component={<Leads  />} />, link: '/leads', isAuthRequired: true},
        { component: <PageLayout type='leads' component={<Leads />} />, link: '/leads/kanban', isAuthRequired: true},
        { component: <PageLayout type='leads' component={<Leads  />} />, link: '/lead/list/:listId', isAuthRequired: true},
        { component: <PageLayout type='leads' component={<Leads showDetailsPage={true} />} />, link: '/lead/:recordId', isAuthRequired: true},
        { component: <PageLayout type='deals' component={<Deals />} />, link: '/deals', isAuthRequired: true},
        { component: <PageLayout type='deals' component={<Deals />} />, link: '/deals/kanban', isAuthRequired: true},
        { component: <PageLayout type='deals' component={<Deals  />} />, link: '/deal/list/:listId', isAuthRequired: true},
        { component: <PageLayout type='deals' component={<Deals showDetailsPage={true} />} />, link: '/deal/:recordId', isAuthRequired: true},
        { component: <PageLayout type='orders' component={<Orders />} />, link: '/orders', isAuthRequired: true},
        { component: <PageLayout type='orders' component={<Orders />} />, link: '/orders/kanban', isAuthRequired: true},
        { component: <PageLayout type='orders' component={<Orders  />} />, link: '/order/list/:listId', isAuthRequired: true},
        { component: <PageLayout type='orders' component={<Orders showDetailsPage={true} />} />, link: '/order/:recordId', isAuthRequired: true},
        { component: <PageLayout type='contacts' component={<Contacts />} />, link: '/contacts', isAuthRequired: true},
        { component: <PageLayout type='contacts' component={<Contacts  />} />, link: '/contact/list/:listId', isAuthRequired: true},
        { component: <PageLayout type='contacts' component={<Contacts showDetailsPage={true} />} />, link: '/contact/:recordId', isAuthRequired: true},
        { component: <PageLayout type='accounts' component={<Accounts />} />, link: '/accounts', isAuthRequired: true},
        { component: <PageLayout type='accounts' component={<Accounts  />} />, link: '/account/list/:listId', isAuthRequired: true},
        { component: <PageLayout type='accounts' component={<Accounts  showDetailsPage={true}/>} />, link: '/account/:recordId', isAuthRequired: true},
        { component: <PageLayout type='users' component={<Users />} />, link: '/users', isAuthRequired: true},
        { component: <PageLayout type='users' component={<Users  />} />, link: '/user/list/:listId', isAuthRequired: true},
        { component: <PageLayout type='users' component={<Users  showDetailsPage={true}/>} />, link: '/user/:recordId', isAuthRequired: true},
        { component: <PageLayout type='profile' component={<Profile />} />, link: '/profile', isAuthRequired: true},
        // { component: <PageLayout type='charts' component={<StandardCharts />} />, link: '/standard-charts', isAuthRequired: true},
        { component: <PageLayout type='permissions' variant='dashboard_layout' component={<RolesAndPermissions />} />, link: '/roles-and-permissions', isAuthRequired: true},
        { component: <PageLayout type='parameters' variant='dashboard_layout' component={<Parameters />} />, link: '/parameters', isAuthRequired: true},
        { component: <PageNotFound />, link: '*', isAuthRequired: true}
    ];

    // Paths that only Digital Finger should have access to
    const internalPaths = [
        ...paths,
        { component: <PageLayout type='tenants' component={<Tenants />} />, link: '/tenants', isAuthRequired: true},
        { component: <PageLayout type='tenants' component={<Tenants  />} />, link: '/tenant/list/:listId', isAuthRequired: true},
        { component: <PageLayout type='tenants' component={<Tenants  showDetailsPage={true}/>} />, link: '/tenant/:recordId', isAuthRequired: true},
        { component: <PageLayout type='tenants' component={<TenantPlans />} />, link: '/tenant-plans', isAuthRequired: true},
        { component: <PageLayout type='tenants' component={<TenantPlans  />} />, link: '/tenant-plan/list/:listId', isAuthRequired: true},
        { component: <PageLayout type='tenants' component={<TenantPlans  showDetailsPage={true}/>} />, link: '/tenant-plan/:recordId', isAuthRequired: true},
    ];

    // Check if the logged in user is a Digital Fingerprint user
    // And if so, give the user access to Digital Fingerprint only paths
    const isDigitalFingerprintTenant = tenantId === digitalFingerprintTenantId;
    const pathsToUse = isDigitalFingerprintTenant ? internalPaths : paths;

    const subdomain = window.location.hostname.split('.')[0];

    return (
        <Router>
            <Routes>
                {subdomain === process.env.REACT_APP_LOGIN_SUB_DOMAIN ? (
                    <>
                        <Route path="*" element={<Login />} />
                        <Route path="/forgot-password" element={<ForgotPassword />} />
                        <Route path="/password-reset/:userId/:token" element={<ResetPassword />} />
                    </>
                ) : (
                    pathsToUse.map((path) => (
                        path.isAuthRequired ? (
                            <Route key={path.link} path={path.link} element={
                                <ProtectedRoute>
                                    {path.component}
                                </ProtectedRoute>
                            }/>
                        ) : (
                            <Route key={path.link} path={path.link} element={path.component} />
                        )
                    ))
                )}
            </Routes>
        </Router>
    );
}