import axios from 'axios';
import Cookies from 'js-cookie';
import { isLocalhost } from '../utils/isLocalhost';

const getAccessToken = () => {
  return Cookies.get('access_token');
};

const getTenantId = () => {
  return Cookies.get('tenantId');
};

const api = axios.create({
  baseURL: process.env.REACT_APP_SERVER_URL,
  headers: {
    authorization: getAccessToken()
  },
});

const setAccessToken = (accessToken) => {
  const cookieOptions = {
    path: '/',
    domain: isLocalhost() ? '.localhost.com' : `.${process.env.REACT_APP_DOMAIN}`,
  };

  Cookies.set('access_token', accessToken, cookieOptions);
};

const refreshAccessToken = async () => {
  try {
    const refreshToken = Cookies.get('refresh_token');
    const response = await api.post('/api/auth/refresh-token', {
      refreshToken,
    });
    const newAccessToken = response.data.accessToken;
    return newAccessToken;
  } catch (error) {
    console.error('Error refreshing access token:', error);
    throw error;
  }
};

api.interceptors.request.use(
  (config) => {
    const accessToken = getAccessToken();
    const tenantId = getTenantId();
    
    // Add access token to authorization header if it exists
    if (accessToken) {
      config.headers.authorization = accessToken;
    }

    // Add tenantId to headers
    if (tenantId) {
      config.headers['X-Tenant-Id'] = tenantId; // You can name the header appropriately
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    return response;
  },
  async (error) => {
    const originalRequest = error.config;

    if (error.response?.status === 403 && !originalRequest._retry) {
      originalRequest._retry = true;

      try {
        const token = await refreshAccessToken();
        setAccessToken(token);
        originalRequest.headers.authorization = token;
        return api(originalRequest);
      } catch (refreshError) {
        console.error('Error refreshing access token:', refreshError);
        throw refreshError;
      }
    }

    return Promise.reject(error);
  }
);

export default api;
