import { useState, useEffect } from 'react';
import { useQueryClient } from 'react-query';
import { Button } from '@mui/material';
import Field from '../../../components/field/Field';
import { configureEmailToCase } from '../../../api/emailToCase';
import { toast } from 'react-toastify';

const EmailToCaseForwardingConfig = ({ data, isLoading, tenantId }) => {
  const queryClient = useQueryClient();
  const [formData, setFormData] = useState({
    isEnabled: false,
    isEmailVerified: false,
    email: '',
  });
  const [isSubmitting, setIsSubmitting] = useState(false);

  useEffect(() => {
    if (data) {
      setFormData({
        isEmailVerified: data?.emailToCase?.isEmailVerified || false,
        isEnabled: data?.emailToCase?.isEnabled || false,
        email: data?.emailToCase?.email || '',
      });
    }
  }, [data]);

  const handleChange = (e) => {
    const { name, value, checked, type } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === 'checkbox' ? checked : value,
    }));
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (formData.isEnabled && !formData.email) {
      toast.error('Please provide an email and enable the feature.');
      return;
    }

    setIsSubmitting(true);

    toast
      .promise(configureEmailToCase({ tenantId, ...formData }), {
        pending: 'Configuring email...',
        success: 'Email configuration saved successfully!',
        error: 'Error configuring email',
      })
      .then(() => {
        queryClient.invalidateQueries(['company-info']);
      })
      .finally(() => {
        setIsSubmitting(false);
      });
  };

  // Function to copy email to clipboard
  const copyToClipboard = (email) => {
    if (navigator.clipboard) {
      navigator.clipboard.writeText(email).then(() => {
        alert('Email copied to clipboard!');
      }).catch(err => {
        console.error('Failed to copy email: ', err);
      });
    } else {
      // Fallback method for older browsers
      const textarea = document.createElement('textarea');
      textarea.value = email;
      document.body.appendChild(textarea);
      textarea.select();
      try {
        document.execCommand('copy');
        alert('Email copied to clipboard!');
      } catch (err) {
        console.error('Failed to copy email: ', err);
      } finally {
        document.body.removeChild(textarea);
      }
    }
  };

  const email = process.env.REACT_APP_EMAIL_TO_CASE_FORWARDING_EMAIL;

  return (
    <form
      onSubmit={handleSubmit}
      style={{ display: 'flex', flexDirection: 'column' }}
    >
      <Field
        label="Enable Email to Case"
        type="checkbox"
        name="isEnabled"
        value={formData?.isEnabled}
        onChange={handleChange}
      />
      {formData?.isEnabled && (
        <Field
          label="Email"
          type="email"
          name="email"
          value={formData?.email}
          onChange={handleChange}
          required
        />
      )}
      {formData?.isEnabled && (
        <div>
          <p
            style={{
              color: formData?.isEmailVerified
                ? 'var(--green-color)'
                : 'var(--red-color)',
            }}
          >
            {formData?.isEmailVerified
              ? 'Email Verified'
              : 'Email Verification Required'}
          </p>
          <p>
            Forward your emails to this email:
            <span
              style={{
                cursor: 'pointer',
                marginLeft: '5px',
                cursor: 'copy'
              }}
              onClick={() => copyToClipboard(email)}
            >
              {email}
            </span>
          </p>
        </div>
      )}
      <div>
        <Button
          size="small"
          type="submit"
          variant="contained"
          style={{ background: 'var(--primary-color)', marginTop: '20px' }}
          disabled={isSubmitting || isLoading}
        >
          {isSubmitting || isLoading ? 'Configuring...' : 'Save Configuration'}
        </Button>
      </div>
    </form>
  );
};

export default EmailToCaseForwardingConfig;
