import styles from './css/Login.module.css';
import Logo from '../../../assets/Main Logo.png';
import Icon from '../../../assets/Icon.png';
import DashboardImage from '../../../assets/orders dashboard.png';
import { useState, useContext } from 'react';
import { Link } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { AuthContext } from '../../../contexts/AuthContext';
import { login as loginEndpoint } from '../../../api/authentication';
import SEO from '../../../components/seo/SEO';
import Copyright from '../../../components/copyright/Copyright';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { isLocalhost, getProtocol } from '../../../utils/isLocalhost';
import { useMediaQuery } from '@mui/material';

const defaultTheme = createTheme();

const initialForm = {
  username: '',
  password: ''
};

export default function SignIn() {
  const isLargeScreen = useMediaQuery('(min-width:600px)');
  const { login } = useContext(AuthContext);
  const { isAuthenticated } = useContext(AuthContext);
  const [ formSubmitErrors, setFormSubmitErrors ] = useState('');
  const [ isSubmitting, setIsSubmitting ] = useState(false);
  const [ credentials, setCredentials ] = useState(initialForm);
  const [cookies,] = useCookies([
    'subdomain',
  ]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    const genericErrorMessage = 'Please check your username and password.';
    setIsSubmitting(true);
    try {
      const response = await loginEndpoint(credentials);
      login(response);

      setIsSubmitting(false);
      setCredentials(initialForm); // Reset form values

      // Redirect to the user's subdomain
      const subdomain = response.subDomain;
      const port = isLocalhost() ? window.location.port : '';
      const mainDomain = isLocalhost() ? `localhost.com:${port}` : process.env.REACT_APP_DOMAIN;
      window.location.replace(`${getProtocol()}://${subdomain}.${mainDomain}`);
    } catch (error) {
      console.error(error);
      setIsSubmitting(false);
      setFormSubmitErrors(genericErrorMessage);
    }
  };

  const customButtonStyle = {
    backgroundColor: 'var(--primary-color)',
    color: 'var(--secondary-text-color)',
    '&:hover': {
      backgroundColor: 'var(--primary-color-hover)'
    },
  };

  const handleFormChange = (event) => {
    const { name, value } = event.target;
    setCredentials({
      ...credentials,
      [name]: value,
    });
  };

  if (isAuthenticated()) {
    const subdomain = cookies.subdomain;
    const port = isLocalhost() ? window.location.port : '3000';
    const mainDomain = isLocalhost() ? `localhost.com:${port}` : process.env.REACT_APP_DOMAIN;
    window.location.replace(`${getProtocol()}://${subdomain}.${mainDomain}`);
  }

  return (
    <ThemeProvider theme={defaultTheme}>
      <SEO
        title="Login | Salesdam"
        description="Login page to Digital Fingerprint Customer Management Tool"
      />
      <Grid container component="main" sx={{ height: '100vh' }}>
        {isLargeScreen && (
          <Grid
            item
            xs={false}
            sm={4}
            md={7}
            sx={{
              background: 'linear-gradient(to right, #8eaccd, #c2e9fb)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexDirection: 'column',
              padding: '20px',
            }}
          >
            <Box
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                gap: '10px',
                height: '80%',
                flexDirection: 'column',
                textAlign: 'center',
                marginBottom: '20px',
              }}
            >
              <Box
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  textAlign: 'center',
                }}
              >
                {/* <img src={Logo} alt="App Logo" className={styles['logo']} /> */}
                <Typography variant="h4" component="h1" gutterBottom sx={{ color: '#fff' }}>
                  Welcome to <img src={Logo} alt="App Logo" className={styles['logo']} />
                </Typography>
                <Typography variant="h6" component="h2" sx={{ color: '#fff', mb: 2 }}>
                  Recognizing that sales are the lifeblood of any business, Salesdam aims to become the vital pulse of your enterprise.
                </Typography>
                <Link className={styles['company-website-link']} to='https://www.salesdam.com/' target="_blank">
                  Learn More
                </Link>
              </Box>
              <Box className={styles['laptop-frame']}>
                <img src={DashboardImage} alt="Dashboard" className={styles['dashboard-image']} />
              </Box>
            </Box>
          </Grid>
        )}
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <Box
            sx={{
              my: 8,
              mx: 4,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}
          >
            <Avatar sx={{ m: 1, width: 56, height: 56, border: 'var(--thin-border)' }} src={Icon} />
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="username"
                label="username"
                name="username"
                autoComplete="username"
                value={credentials.username}
                onChange={handleFormChange}
                autoFocus
              />
              <TextField
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                value={credentials.password}
                onChange={handleFormChange}
              />
              <div className={styles['error-message']}>
                {formSubmitErrors}
              </div>
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ ...customButtonStyle, mt: 3, mb: 2 }}
                disabled={!credentials.password || !credentials.username || isSubmitting}
                className={styles['custom-button']}
              >
                {isSubmitting ? 'SIGNING IN...' : 'SIGN IN'}
              </Button>
              <Link className={styles['forgot-password']} to="/forgot-password">
                Forgot password?
              </Link>
            </Box>
          </Box>
          <Copyright />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
}