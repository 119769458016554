import styles from './css/Toolbar.module.css';
import { useState, useEffect, useContext } from 'react';
import { AuthContext } from '../../contexts/AuthContext';
import { Popover, List, ListItem, ListItemButton, ListItemText, /*Badge*/ } from '@mui/material';
// import Avatar from '@mui/material/Avatar';
// import NotificationsOutlinedIcon from '@mui/icons-material/NotificationsOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
// import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import FullscreenIcon from '@mui/icons-material/Fullscreen';
import FullscreenExitIcon from '@mui/icons-material/FullscreenExit';
import IconContainer from '../../components/iconContainer/IconContainer';

export default function Toolbar() {
  const { logout } = useContext(AuthContext);
  const [isFullScreen, setIsFullScreen] = useState(false);
  const [anchorElSettings, setAnchorElSettings] = useState(null);
  const [anchorElNotifications, setAnchorElNotifications] = useState(null);

  const handleClickSettings = (event) => {
    setAnchorElSettings(event.currentTarget);
  };

  const handleCloseSettings = () => {
    setAnchorElSettings(null);
  };

  // const handleClickNotifications = (event) => {
  //   setAnchorElNotifications(event.currentTarget);
  // };

  const handleCloseNotifications = () => {
    setAnchorElNotifications(null);
  };

  const openSettings = Boolean(anchorElSettings);
  const openNotifications = Boolean(anchorElNotifications);

  useEffect(() => {
    document.addEventListener('fullscreenchange', handleFullScreenChange);

    return () => {
      document.removeEventListener('fullscreenchange', handleFullScreenChange);
    };
  }, []);

  const handleFullScreenChange = () => {
    setIsFullScreen(document.fullscreenElement !== null);
  };

  const toggleFullScreen = () => {
    if (!document.fullscreenElement) {
      document.documentElement.requestFullscreen();
    } else {
      if (document.exitFullscreen) {
        document.exitFullscreen();
      }
    }
  };

  const handleLogoutClick = () => {
    logout();
  };

  const notifications = [
    {
      id: 1,
      title: 'New Lead Assigned',
      description: 'You have been assigned a new lead: John Doe'
    },
    {
      id: 2,
      title: 'Meeting Reminder',
      description: 'Reminder: Meeting with client scheduled for tomorrow at 10:00 AM'
    },
    {
      id: 3,
      title: 'Task Due',
      description: 'Task "Follow up with leads" is due today'
    }
  ];

  return (
    <section className={styles['toolbar']}>
      <h4 className={styles['workspace-title']}>Sales</h4>
      <ul className={styles['nav-user-menu']}>
        <li className={styles['user-menu-icon']} onClick={toggleFullScreen}>
          {isFullScreen ? (
            <IconContainer isActive={true} icon={<FullscreenExitIcon />} />
          ) : (
            <IconContainer isActive={true} icon={<FullscreenIcon />} />
          )}
        </li>
        {/* <li className={styles['user-menu-icon']}>
          <SearchOutlinedIcon />
        </li> */}
        <li className={styles['user-menu-icon']} onClick={handleClickSettings}>
          <IconContainer isActive={true} icon={<SettingsOutlinedIcon />} />
        </li>
        {/* <li className={styles['user-menu-icon']} onClick={handleClickNotifications}>
          <Badge badgeContent={notifications.length} max={10} color="primary">
            <NotificationsOutlinedIcon />
          </Badge>
        </li>
        <li>
          <Avatar sx={{ width: 32, height: 32 }} src="/static/images/avatar/1.jpg" />
        </li> */}
      </ul>
      {/* Settings Popover */}
      <Popover
        open={openSettings}
        anchorEl={anchorElSettings}
        onClose={handleCloseSettings}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
          {/* <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="Settings" />
            </ListItemButton>
          </ListItem>
          <ListItem disablePadding>
            <ListItemButton>
              <ListItemText primary="My Profile" />
            </ListItemButton>
          </ListItem> */}
          <ListItem disablePadding>
            <ListItemButton onClick={handleLogoutClick}>
              <ListItemText primary="Logout" />
            </ListItemButton>
          </ListItem>
        </List>
      </Popover>

      {/* Notifications Popover */}
      <Popover
        open={openNotifications}
        anchorEl={anchorElNotifications}
        onClose={handleCloseNotifications}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
        <List>
        {notifications.map(notification => (
          <ListItem disablePadding key={notification.id}>
            <ListItemButton>
              <ListItemText primary={notification.title} secondary={notification.description} />
            </ListItemButton>
          </ListItem>
        ))}
        </List>
      </Popover>
    </section>
  );
}
