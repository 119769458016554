import SalesTargets from '../../../../components/salesTargets/SalesTargets';
import { getRecords } from '../../../../api/crud';
import { useQuery } from 'react-query';
import { today, subtractMonthsFromDate } from '../../../../utils/dateUtils';

// Function to calculate the total value of a field from records within a date range
const calculateTotal = (records, from, to, field, dateField) => {
  return records
    ?.filter(record => {
      const date = new Date(record[dateField]);
      const value = record[field];
      return date >= new Date(from) && date <= new Date(to) && value !== undefined && !isNaN(value);
    })
    ?.reduce((total, record) => total + record[field], 0);
};

const OrdersTarget = () => {
  // No need to apply tenantId filter for the standard chart
  // The backend already applies the tenantId filter
  const { data: records = [], isLoading } = useQuery({
    queryFn: () => getRecords('standard-charts', chartFilter),
    queryKey: ['sales-target'],
  });

  const { data: orders, isLoading: ordersLoading } = useQuery({
    queryFn: () => getRecords('orders', ordersFilter),
    queryKey: ['orders-targets'],
  });

  const chartFilter = {
    filterLogic: null,
    filters: [
      {
        field: 'name',
        operator: 'equals',
        value: 'sales_target',
      },
    ],
  };

  const chartRecord = records.length > 0 ? records[0] : '';
  const caption = records.length > 0 ? records[0].caption : '';
  const target = records.length > 0 ? records[0].targetValue : 0;
  const from = records.length > 0 ? records[0].from : subtractMonthsFromDate(today(), 4);
  const to =  records.length > 0 ? records[0].to : today();
  const field = records.length > 0 ? records[0].targetField : '';
  const dateField = records.length > 0 ? records[0].dateField : '';
  const total = calculateTotal(orders, from, to, field, dateField) || 0;

  const ordersFilter = {
    filterLogic: '1 AND 2',
    filters: [
      {
        field: 'invoiceDate',
        operator: 'greaterOrEqual',
        value: from,
      },
      {
        field: 'invoiceDate',
        operator: 'lessOrEqual',
        value: to,
      },
    ],
  };

  return (
    <SalesTargets
      chartRecord={chartRecord}
      caption={caption}
      target={target}
      value={total}
      from={from}
      to={to}
      isLoading={isLoading || ordersLoading}
    />
  );
};

export default OrdersTarget;
