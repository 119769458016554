import { Link } from 'react-router-dom';
import OpenInNewOutlinedIcon from '@mui/icons-material/OpenInNewOutlined';

const CustomLink = ({ to, text, onClick }) => {
  const linkStyle = {
    color: 'var(--primary-text-color)',
    textDecoration: 'none',
    display: 'flex', // Use Flexbox
    alignItems: 'center', // Align items vertically in the center
  };

  const styles = {
    color: 'var(--primary-text-color)',
    display: 'flex', // Use Flexbox
    alignItems: 'center', // Align items vertically in the center
    cursor: 'pointer'
  };

  return (
    <>
      {to ? (
        <Link style={linkStyle} to={to}>
          <OpenInNewOutlinedIcon style={{color: 'var(--blue-color)'}} fontSize='small' /> {/* Icon */}
          <span style={{ marginLeft: '4px' }}>{text}</span> {/* Text with margin to separate from icon */}
        </Link>
      ) : (
        <span style={styles} onClick={onClick}>
          <OpenInNewOutlinedIcon style={{color: 'var(--blue-color)'}} fontSize='small' /> {/* Icon */}
          <span style={{ marginLeft: '4px' }}>{text}</span> {/* Text with margin to separate from icon */}
        </span>
      )}
    </>
  )
};

export default CustomLink;