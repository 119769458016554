import styles from './css/Parameters.module.css';
import { useContext } from 'react';
import { useQuery } from 'react-query';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Typography,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { AuthContext } from '../../contexts/AuthContext';
import { getRecord } from '../../api/crud';
import Header from '../../layouts/header/Header';
import SEO from '../../components/seo/SEO';
import CompanyInfoForm from './sections/CompanyInfoForm';
import RevenueTargetsForm from './sections/RevenueTargetsForm';
import SalesTargetsForm from './sections/SalesTargetsForm';
// import EmailToLeadConfig from './sections/EmailToLeadConfig';
import EmailToLeadForwardingConfig from './sections/EmailToLeadForwardingConfig';
import EmailToCaseForwardingConfig from './sections/EmailToCaseForwardingConfig';

const Parameters = () => {
  const { getUserId } = useContext(AuthContext);
  const userId = getUserId();

  const { data: user } = useQuery({
    queryFn: () => getRecord('user', userId),
    queryKey: ['logged-in-user'],
  });

  const tenantId = user?.tenantId;

  const { data: companyInfo, isLoading } = useQuery({
    queryFn: () => getRecord('tenant', tenantId),
    queryKey: ['company-info'],
    enabled: !!user,
  });

  const infoText = 'On this page you can update your company info, chart configurations, and more';

  return (
    <>
      <SEO
        title="Parameters | Salesdam"
        description={infoText}
      />
      <Header
        headerText="Home"
        infoText={infoText}
      />
      <div className={styles['parameters-container']}>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Company Info</Typography>
          </AccordionSummary>
          <AccordionDetails className={styles['accordion-details']}>
            <CompanyInfoForm
              isLoading={isLoading}
              tenantId={companyInfo?._id}
              data={companyInfo}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Revenue Targets</Typography>
          </AccordionSummary>
          <AccordionDetails className={styles['accordion-details']}>
            <RevenueTargetsForm
              isLoading={isLoading}
              tenantId={companyInfo?._id}
              data={companyInfo}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Sales Targets</Typography>
          </AccordionSummary>
          <AccordionDetails className={styles['accordion-details']}>
            <SalesTargetsForm
              isLoading={isLoading}
              tenantId={companyInfo?._id}
              data={companyInfo}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Email to Lead Config</Typography>
          </AccordionSummary>
          <AccordionDetails className={styles['accordion-details']}>
            <EmailToLeadForwardingConfig
              isLoading={isLoading}
              tenantId={companyInfo?._id}
              data={companyInfo}
            />
          </AccordionDetails>
        </Accordion>
        <Accordion>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>Email to Case Config</Typography>
          </AccordionSummary>
          <AccordionDetails className={styles['accordion-details']}>
            <EmailToCaseForwardingConfig
              isLoading={isLoading}
              tenantId={companyInfo?._id}
              data={companyInfo}
            />
          </AccordionDetails>
        </Accordion>
      </div>
    </>
  );
};

export default Parameters;
