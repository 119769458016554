import styles from './css/PageLayout.module.css';
import { useState, useEffect } from 'react';
import { useQuery } from 'react-query';
import AutoLogout from '../components/appLogout/AutoLogout';
import Toolbar from './toolbar/Toolbar';
import Sidebar from './sidebar/Sidebar';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { getUserPermissions } from '../api/authentication';
import { ClipLoader } from 'react-spinners';

/**
 * variant possible values 'dashboard_layout'
 * type possible values: 'leads', 'tasks', 'accounts', etc.
 */
export default function PageLayout({ component, variant, type }) {
  const [hasMounted, setHasMounted] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(true);

  const { data: role, isLoading, isError } = useQuery({
    queryFn: () => getUserPermissions(),
    queryKey: ['logged-in-user-permissions']
  });
  const roleName = role?.name;
  const permissions = role?.permissions;

  const toggleDrawer = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  useEffect(() => {
    setHasMounted(true);
  }, []);

  const permissionsCheckNotRequired = ['emails', 'home', 'dashboard', 'users', 'profile', 'charts','permissions', 'tenants', 'parameters'];
  const adminOnly = ['users', 'parameters', 'charts', 'permissions'];

  const hasPermission = (type) => {
    if (permissionsCheckNotRequired.includes(type)) {
      return true;
    }

    // Items that can only be viewed by Superusers, and, or Admins
    if (adminOnly.includes(type) && roleName !== 'Superuser' && roleName !== 'Admin') {
      return false;
    }

    if (!role || !permissions) {
      return false;
    }

    return permissions[type]?.view;
  };

  return (
    <AutoLogout>
      {hasMounted && (
        <div className={styles['page-layout']}>
          <ToastContainer
            position='top-center'
            autoClose={3000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss={false}
            pauseOnHover
            theme='colored'
          />
          <Sidebar
            isMenuOpen={isMenuOpen}
            toggleDrawer={toggleDrawer}
          />
          <section className={styles['layout-container']}>
            <Toolbar
              isMenuOpen={isMenuOpen}
            />
            <main className={(variant === 'dashboard_layout' || variant === 'emails_page') ? styles[variant] : styles['entity-layout']}>
              {isLoading ? (
                <div className={styles['spinner-container']}>
                  <ClipLoader size={50} color="var(--primary-color)" />
                </div>
              ) : isError ? (
                <div className={styles['error-message']}>Error loading permissions</div>
              ) : hasPermission(type) ? (
                component
              ) : (
                <div className={styles['access-denied']}>Access Denied</div>
              )}
            </main>
          </section>
        </div>
      )}
    </AutoLogout>
  );
}